<template>
  <div class="container">
    <div v-for="(item, index) in contents" :key="index">
      <div v-for="(c, indexL) in item" :key="indexL">
        <div>
          <h3>{{ c.toefl_title }} - Passage {{ c.order }}</h3>
          <div v-if="c.order === 1">
            <p>
              {{ c.questions[0].question }}
            </p>
            <h6>
              Article
            </h6>
            <div>
              {{ c.questions[0].content.article }}
            </div>
            <hr />
          </div>
          <div v-if="c.order === 2">
            <p>
              {{ c.questions[0].question }}
            </p>
            <h6>
              Question
            </h6>
            <div>
              {{ c.questions[0].content.article }}
            </div>
          </div>
        </div>
      </div>

      <hr class="hr" />
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import TOEFL from "@/apis/toefl";

export default {
  metaInfo() {
    return {
      title: "Writing Content - " + this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      contents: []
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.getWriting();
  },

  methods: {
    async getWriting() {
      let res = await TOEFL.getWriting();
      this.contents = res;
    }
  }
};
</script>

<style scoped>
.hr {
  border-width: 5px;
  border-color: black;
}
</style>
